export const CART_ADD_REQUEST = 'CART_ADD_REQUEST';
export const CART_ADD_SUCCESS = 'CART_ADD_SUCCESS';
export const CART_ADD_FAIL = 'CART_ADD_FAIL';

export const CART_ADD_RESET = 'CART_ADD_RESET';

export const CART_BADGE_REQUEST = ' CART_BADGE_REQUEST';
export const CART_BADGE_SUCCESS = ' CART_BADGE_SUCCESS';
export const CART_BADGE_FAIL = ' CART_BADGE_FAIL';
export const CART_BADGE_RESET = ' CART_BADGE_RESET';

export const CART_UPDATE_BACK_REQUEST = 'CART_UPDATE_BACK_REQUEST';
export const CART_UPDATE_BACK_SUCCESS = 'CART_UPDATE_BACK_SUCCESS';
export const CART_UPDATE_BACK_FAIL = 'CART_UPDATE_BACK_FAIL';
export const CART_UPDATE_BACK_RESET = 'CART_UPDATE_BACK_RESET';

export const CART_REMOVE_NA_REQUEST = 'CART_REMOVE_NA_REQUEST';
export const CART_REMOVE_NA_SUCCESS = 'CART_REMOVE_NA_SUCCESS';
export const CART_REMOVE_NA_FAIL = 'CART_REMOVE_NA_FAIL';
