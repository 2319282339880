export const INVITE_TO_MY_FAMILY_REQUEST = 'INVITE_TO_MY_FAMILY_REQUEST';
export const INVITE_TO_MY_FAMILY_SUCCESS = 'INVITE_TO_MY_FAMILY_SUCCESS';
export const INVITE_TO_MY_FAMILY_FAIL = 'INVITE_TO_MY_FAMILY_FAIL';

export const JOIN_VIRTUAL_FAMILY_REQUEST = 'JOIN_VIRTUAL_FAMILY_REQUEST';
export const JOIN_VIRTUAL_FAMILY_SUCCESS = 'JOIN_VIRTUAL_FAMILY_SUCCESS';
export const JOIN_VIRTUAL_FAMILY_FAIL = 'JOIN_VIRTUAL_FAMILY_FAIL';
export const JOIN_VIRTUAL_FAMILY_RESET = 'JOIN_VIRTUAL_FAMILY_RESET';

export const LEAVE_VIRTUAL_FAMILY_REQUEST = 'LEAVE_VIRTUAL_FAMILY_REQUEST';
export const LEAVE_VIRTUAL_FAMILY_SUCCESS = 'LEAVE_VIRTUAL_FAMILY_SUCCESS';
export const LEAVE_VIRTUAL_FAMILY_FAIL = 'LEAVE_VIRTUAL_FAMILY_FAIL';
export const LEAVE_VIRTUAL_FAMILY_RESET = 'LEAVE_VIRTUAL_FAMILY_RESET';

export const ACCEPT_INVITATION_REQUEST = 'ACCEPT_INVITATION_REQUEST';
export const ACCEPT_INVITATION_SUCCESS = 'ACCEPT_INVITATION_SUCCESS';
export const ACCEPT_INVITATION_FAIL = 'ACCEPT_INVITATION_FAIL';
export const ACCEPT_INVITATION_RESET = 'ACCEPT_INVITATION_RESET';

export const FAMILY_NETWORK_REQUEST = 'FAMILY_NETWORK_REQUEST';
export const FAMILY_NETWORK_SUCCESS = 'FAMILY_NETWORK_SUCCESS';
export const FAMILY_NETWORK_FAIL = 'FAMILY_NETWORK_FAIL';
export const FAMILY_NETWORK_RESET = 'FAMILY_NETWORK_RESET';
