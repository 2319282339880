export const SHAPARAK_PAYMENT_REQUEST = 'SHAPARAK_PAYMENT_REQUEST';
export const SHAPARAK_PAYMENT_SUCCESS = 'SHAPARAK_PAYMENT_SUCCESS';
export const SHAPARAK_PAYMENT_FAIL = 'SHAPARAK_PAYMENT_FAIL';
export const SHAPARAK_RESET = 'SHAPARAK_RESET';

export const SHAPARAK_CART_PAYMENT_REQUEST = 'SHAPARAK_CART_PAYMENT_REQUEST';
export const SHAPARAK_CART_PAYMENT_SUCCESS = 'SHAPARAK_CART_PAYMENT_SUCCESS';
export const SHAPARAK_CART_PAYMENT_FAIL = 'SHAPARAK_CART_PAYMENT_FAIL';

export const CHECK_CART_PAYMENT_REQUEST = 'CHECK_CART_PAYMENT_REQUEST';
export const CHECK_CART_PAYMENT_SUCCESS = 'CHECK_CART_PAYMENT_SUCCESS';
export const CHECK_CART_PAYMENT_FAIL = 'CHECK_CART_PAYMENT_FAIL';
export const CHECK_CART_PAYMENT_RESET = 'CHECK_CART_PAYMENT_RESET';
